.errorClose {
  @apply overflow-hidden cursor-pointer;

  width: 19px;
  height: 19px;
  background-position: -11px -60px;
  background-image: url("../../assets/tooltip-red.png");
}

.errorClose:hover {
  background-position: -11px -40px;
}
