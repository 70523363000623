.virtual-keyboard {
  padding: 18px 39px 18px 28px;
  background: #ecf7e0;
  border: 1px solid #9c3;
  border-radius: 5px;
  box-shadow: 0 0 5px #bbb;
}

.hg-row {
  @apply grid;

  grid-template-columns: auto 140px;
  column-gap: 18px;
}

.hg-button-container {
  @apply flex;
}

.hg-button-container:first-child {
  @apply justify-center;
}

.hg-button {
  @apply relative flex items-center justify-center text-xs bg-white border border-solid;

  margin: 2px 2px 3px 3px;
  width: 30px;
  height: 30px;
  border-color: #dadada;
  border-radius: 5px;
}

.hg-button span {
  @apply pointer-events-none;
}

.hg-button:focus {
  @apply outline-none;
}

.hg-button:hover {
  top: 1px;
  border-color: #b4b4b4;
}

.hg-button.hg-activeButton {
  background-color: #9c3;
  border: 1px solid #9c3;
}

.hg-button-bksp,
.hg-button-lock {
  @apply bg-no-repeat;

  width: 38px;
  background-color: whitesmoke;
  background-image: url("../../assets/keys.png");
}

.hg-button-bksp {
  background-position: 0 -28px;
}
