.ul {
  @apply list-inside;
}

.ul li {
  @apply flex;
}

.ul li::before {
  @apply flex-grow-0 flex-shrink-0 inline-block w-4 text-green-brand text-xl leading-none;

  content: "\2022";
}
