.wrapper {
  min-height: 600px;
}

@media (min-width: theme("screens.sm")) {
  .wrapper {
    padding-right: 300px;
  }
}

@media (min-width: theme("screens.sm")) {
  .form {
    width: 280px;
  }
}
