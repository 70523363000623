::-moz-selection,
::selection {
  @apply bg-green-highlight;
  @apply text-black-3;

  text-shadow: none;
}

body {
  @apply bg-black-fa;

  font-family: arial, helvetica, "Nimbus Sans L", "Bitstream Vera Sans",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
