.phone {
  background-image: url("../../assets/sprite.svg");
  background-position: 0 -1074px;
}

.assistant {
  background-image: url("../../assets/aneta.svg");
  background-position: 0 2px;
  background-size: 14px;
}
