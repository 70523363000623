.buttonBlack {
  background: linear-gradient(180deg, #474747 0%, #232323 100%),
    linear-gradient(
      180deg,
      #4c4c4c 0%,
      #3f3f3f 47.82%,
      #232323 49.52%,
      #292929 100%
    );
}

.buttonBlack:hover {
  background: linear-gradient(180deg, #7a7a7a 0%, #575757 100%),
    linear-gradient(
      180deg,
      #808080 0%,
      #737373 47.82%,
      #575757 49.52%,
      #5c5c5c 100%
    );
}

.buttonWhite {
  background: linear-gradient(
    180deg,
    #efefef 0%,
    #fff 48.44%,
    rgba(238, 238, 238, 0.787879) 48.45%,
    #dadada 100%
  );
}

.buttonWhite:hover {
  background: linear-gradient(
    180deg,
    #efefef 0%,
    #fff 48.44%,
    rgba(238, 238, 238, 0.787879) 48.45%,
    #dedede 100%
  );
  box-shadow: 0 0 0 2px #b4b4b4;
}

.buttonGreen {
  background: linear-gradient(
      180deg,
      #9adc03 0%,
      #9adc03 51.04%,
      #84c600 51.05%,
      #6fb000 100%
    ),
    linear-gradient(
      180deg,
      #efefef 0%,
      #fff 48.44%,
      rgba(238, 238, 238, 0.787879) 48.45%,
      #dadada 10%
    );
}

.buttonGreen:hover {
  background: linear-gradient(180deg, #bee393 50%, #a8d17c 50%);
}
