.input-wrapper {
  background: linear-gradient(180deg, #eaeaea 0%, #fefefe 100%), #fff;
}

.no-spin-button {
  -moz-appearance: textfield;
}
.no-spin-button::-webkit-inner-spin-button,
.no-spin-button::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.virtual-keyboard-close {
  @apply mr-2 mt-2 overflow-hidden cursor-pointer;

  width: 19px;
  height: 19px;
  background-position: -11px -60px;
  background-image: url("../../../assets/tooltip-green.png");
}

.virtual-keyboard-close:hover {
  background-position: -11px -40px;
}
